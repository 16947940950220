<template>
  <div class="vap-page">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="true"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>Global Settings</h1>
      <div class="btns-container">
        <VasionButton
            id="btn-save"
            class="last-btn"
            classProp="primary"
            :isDisabled="disableSaveButton"
            @vasionButtonClicked="saveGlobalSettings()"
          >
            Save
          </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="section-container">
      <div class="section-content">
        <div class="form-container">
          <div class="input-container mb-3">
            <VasionInput
              id="workflow-web-url"
              v-model="formData.workflowWebURL.value"
              name="workflowWebURL"
              title="Workflow Web URL"
              class="input-style"
              inputType="top-white"
              specialType="url"
              @input="markAsDirty(); checkInput('workflowWebURL', formData.workflowWebURL.value)"
              @notValid="fieldNotValid('workflowWebURL')"
              @isValid="fieldIsValid('workflowWebURL')"
            />
            <span v-if="invalidFields.includes('workflowWebURL')" class="error-message">You must enter a valid URL.</span>
            <span v-else-if="emptyFields.includes('workflowWebURL')" class="error-message">The value of this field must not be empty.</span>
          </div>
          <div class="input-container mb-3">
            <VasionInput
              id="max-search-results"
              v-model="formData.maxSearchResults.value"
              name="maxSearchResults"
              title="Max Search Results"
              class="input-style"
              inputType="top-white"
              specialType="number"
              @input="markAsDirty(); checkInput('maxSearchResults', formData.maxSearchResults.value)"
            />
            <span v-if="invalidFields.includes('maxSearchResults')" class="error-message">You must enter a valid number.</span>
            <span v-else-if="emptyFields.includes('maxSearchResults')" class="error-message">The value of this field must not be empty.</span>
          </div>
          <div class="input-container mb-3" v-if="bridgeEnabled">
            <VasionInput
              id="vasion-automate-url"
              v-model="formData.vasionAutomateUrl.value"
              name="vasionAutomateUrl"
              title="Vasion Automate URL"
              class="input-style"
              inputType="top-white"
              specialType="url"
              @input="markAsDirty(); checkInput('vasionAutomateUrl', formData.vasionAutomateUrl.value)"
              @notValid="fieldNotValid('vasionAutomateUrl')"
              @isValid="fieldIsValid('vasionAutomateUrl')"
            />
            <span v-if="invalidFields.includes('vasionAutomateUrl')" class="error-message">You must enter a valid URL.</span>
          </div>
          <div class="input-container mb-3" v-if="bridgeEnabled">
            <VasionInput
              id="vasion-print-url"
              v-model="formData.vasionPrintUrl.value"
              name="vasionPrintUrl"
              title="Vasion Print URL"
              class="input-style"
              inputType="top-white"
              specialType="url"
              @input="markAsDirty(); checkInput('vasionPrintUrl', formData.vasionPrintUrl.value)"
              @notValid="fieldNotValid('vasionPrintUrl')"
              @isValid="fieldIsValid('vasionPrintUrl')"
            />
            <span v-if="invalidFields.includes('vasionPrintUrl')" class="error-message">You must enter a valid URL.</span>
          </div>
        </div>
        <div v-show="showThisInTheInterface" class="fields-container">
          <VasionDropList
            v-slot="slotItem"
            v-model="formData.systemDateFormat.value"
            title="Default Date Format"
            :dataArray="systemDateFormatList"
            width="100%"
            type="plain-list"
            displayName="displayName"
            valueName="value"
            :showIfEmpty="true"
            @input="markAsDirty"
          >
            {{ slotItem.item.displayName }}
          </VasionDropList>
          <VasionDropList
            v-slot="slotItem"
            v-model="formData.systemTimeFormat.value"
            title="Default Time Format"
            :dataArray="systemTimeFormatList"
            width="100%"
            type="plain-list"
            displayName="displayName"
            valueName="value"
            :showIfEmpty="true"
            @input="markAsDirty"
          >
            {{ slotItem.item.displayName }}
          </VasionDropList>
        </div>
        <div v-show="showThisInTheInterface" class="fields-container">
          <VasionDropList
            v-slot="slotItem"
            v-model="currencyFromDropList"
            title="Currency Type"
            :dataArray="systemCurrencyFormatList"
            width="100%"
            type="plain-list"
            displayName="displayName"
            valueName="value"
            :showIfEmpty="true"
            @input="updateCurrencyDroplist"
          >
            {{ slotItem.item.displayName }}
          </VasionDropList>
          <div>
            <VasionInput
              id="system-currency-format"
              v-model="formData.systemCurrencyFormat.value"
              name="systemCurrencyFormat"
              title="Default Currency Format"
              class="input-style"
              inputType="top-white"
              @input="updateCurrencyFormat"
            />
            <div v-if="showCurrencySymbolHint" class="hint">*Must manually enter currency symbol</div>  
          </div>
          <div>
            <VasionInput
              id="currency-format-example"
              v-model="currencyFormatExample"
              name="currencyFormatExample"
              title="Currency Format Example"
              class="input-style"
              inputType="top-white"
              :isDisabled="true"
              :readonly="true"
            />
          </div>
        </div>
      </div>
    </div>
    </div>
    <VasionGeneralModal
      id="mdl-prevent-exit-without-save"
      :confirmButtonText="'YES'"
      :rejectButtonText="'NO'"
      :modalType="'message'"
      :buttonGroup="'center'"
      :message="'Your changes have not been saved, are you sure you want to cancel?'"
      :sync="showLeaveDialog"
      @noButtonClick="cancelLeave()"
      @confirmButtonClick="doLeave()"
    />
    <VasionSnackbar
      id="email-settings-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import cloneDeep from 'lodash/cloneDeep'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import lists from './globalSettingsFieldsList.json';
import { formatCurrency } from '@/store/helperModules/common.module.js'

export default {
  name: 'GlobalSettings',
  components: {
    Loading,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      showThisInTheInterface: true,
      bridgeEnabled: false,
      currencyFormatExample: '',
      currencyFromDropList: '',
      emptyFields: [],
      formData: {
        maxSearchResults: {
          value: '',
          backendName: 'MaxSearchResult',
        },
        systemDateFormat: {
          value: '',
          backendName: 'SystemDateFormat',
        },
        systemCurrencyFormat: {
          value: '',
          backendName: 'SystemCurrencyFormat',
        },
        systemTimeFormat: {
          value: '',
          backendName: 'SystemTimeFormat',
        },
        vasionAutomateUrl: {
          value: '',
          backendName: 'VasionAutomateURL',
        },
        vasionPrintUrl: {
          value: '',
          backendName: 'VasionPrintURL',
        },
        workflowWebURL: {
          value: '',
          backendName: 'WFURL',
        },
      },
      fieldsFromSystemSettings: ['SystemDateFormat', 'SystemCurrencyFormat', 'SystemTimeFormat'],
      formDataInitialState: {},
      invalidFields: [],
      isDirty: false,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      showLeaveDialog: false,
      showCurrencySymbolHint: false,
      showSnackbar: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      systemCurrencyFormatList: lists.systemCurrencyFormat,
      systemDateFormatList: lists.systemDateFormats,
      systemTimeFormatList: lists.systemTimeFormat,
    }
  },
  computed: {
    disableSaveButton() { return !this.isDirty || this.emptyFields.length !== 0 || this.invalidFields.length !== 0 },
  },
  async created() {
    this.isLoading = true

    await this.getGlobalSettings()
    this.formDataInitialState = cloneDeep(this.formData)

    this.isLoading = false
  },
  methods: {
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    checkInput(fieldName, fieldValue) {
      if (fieldValue === "" && fieldName !== "vasionPrintUrl" && fieldName !== "vasionAutomateUrl") {
        if(!this.emptyFields.includes(fieldName)) { this.emptyFields.push(fieldName) }
      }else {
        this.emptyFields = this.emptyFields.filter(element => element !== fieldName);
      }
    },
    doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    fieldNotValid(fieldName) {
      if (!this.invalidFields.includes(fieldName)) {
        this.invalidFields.push(fieldName)
      }
    },
    fieldIsValid(fieldName) {
      this.invalidFields = this.invalidFields.filter(element => element !== fieldName);
    },
    async getGlobalSettings() {
      this.formData.workflowWebURL.value = await this.$store.dispatch('common/getConfig', this.formData.workflowWebURL.backendName)
      this.formData.maxSearchResults.value = await this.$store.dispatch('common/getConfig', this.formData.maxSearchResults.backendName)
      this.bridgeEnabled = this.$store.state.systemSettings.featureFlags.vasionAutomateBridge
      this.formData.vasionAutomateUrl.value = this.$store.state.systemSettings.vasionAutomateURL
      this.formData.vasionPrintUrl.value = this.$store.state.systemSettings.vasionPrintURL
      
      const systemDateFormat = this.$store.state.systemSettings.systemDateFormat

      this.formData.systemDateFormat.value = await this.systemDateFormatList.find(item => item.value === systemDateFormat)
      const systemTimeFormat = this.$store.state.systemSettings.systemTimeFormat
      this.formData.systemTimeFormat.value = await this.systemTimeFormatList.find(item => item.value === systemTimeFormat)
      const systemCurrencyFormat = this.$store.state.systemSettings.systemCurrencyFormat
      this.formData.systemCurrencyFormat.value = systemCurrencyFormat
      this.currencyFromDropList = await this.systemCurrencyFormatList.find(item => item.value === systemCurrencyFormat)
      // If the currency format doesn't match, it must be custom
      if (!this.currencyFromDropList)
        this.currencyFromDropList = await this.systemCurrencyFormatList.find(item => item.value === '')
      this.updateCurrencyFormat()
      this.markAsClean()
    },
    async getModifiedVariables(initialState, currentState) {
      let changes = {}
      for (const key in initialState) {
        const initialVal = initialState[key]?.value
        const currentVal = currentState[key]?.value

        if ((typeof initialVal === 'object' && initialVal !== null &&
             typeof currentVal === 'object' && currentVal !== null &&
             initialVal.value !== currentVal.value) ||
            (typeof currentVal !== 'object' && initialVal !== currentVal)) {
          changes[key] = currentState[key]
        }
      }
      return changes
    },
    markAsClean() {
      this.isDirty = false
    },
    markAsDirty() {
      this.isDirty = true
    },
    async saveGlobalSettings() {
      this.isLoading = true
      if (this.formData.vasionAutomateURL?.value) {
        this.formData.vasionAutomateURL.value = this.stripTrailingSlash(this.formData.vasionAutomateURL.value)
      }
      if (this.formData.vasionPrintUrl?.value) {
        this.formData.vasionPrintUrl.value = this.stripTrailingSlash(this.formData.vasionPrintUrl.value)
        this.formData.vasionPrintUrl.value = this.formData.vasionPrintUrl.value.replace(/\/admin$/i, '')
      }

      const changes = await this.getModifiedVariables(this.formDataInitialState, this.formData)

      try {
        for (const key in changes) {
          let payload = {
            sConfigName: changes[key].backendName,
            sConfigValue: changes[key].value,
          }

          if (typeof changes[key].value === 'object' && changes[key].value !== null) {
            payload.sConfigValue = changes[key].value.value
          }

          await this.$store.dispatch('common/setConfig', payload)
        }
      } catch (error) {
        this.isLoading = false
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'Error while saving global settings.'
        this.snackbarImage = false
        this.showSnackbar = true
      }
      this.isLoading = false
      this.snackbarTitle = 'Success'
      this.snackbarSubTitle = 'Global settings saved.'
      this.snackbarImage = true
      this.showSnackbar = true

      this.formDataInitialState = cloneDeep(this.formData)
      this.markAsClean()

      if (Object.keys(changes).some(key => this.fieldsFromSystemSettings.includes(changes[key].backendName))) {
        const userToken = this.$store.state.common.apiToken
        await this.$store.dispatch('systemSettings/reloadSystemSettings', userToken)
      }
    },
    stripTrailingSlash(url){
      return url.replace(/\/$/, '')
    },

    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    updateCurrencyDroplist() {
      this.showCurrencySymbolHint = (this.currencyFromDropList.value === '')
      this.formData.systemCurrencyFormat.value = this.currencyFromDropList.value
      this.updateCurrencyFormat()
      this.markAsDirty()
    },
    updateCurrencyFormat() {
      this.currencyFormatExample = formatCurrency(1234.561, this.formData.systemCurrencyFormat.value)
      this.markAsDirty()
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.section-container {
  margin-left: 1rem;
  margin-top: 1rem;
  margin-right: 1rem;
}
.section-header {
  min-height: 68px;
}
.section-content {
  height: calc(100vh - 240px);
  overflow: auto;
}
.form-container {
  width: 100%;
}
.mb-3 {
  margin-bottom: 16px;
}
.error-message {
  color: $error-red;
  font-weight: 200;
}
.hint {
  color: #3D2562; // $primary in vasion-automate
  font-size: 11px;
}

div.fields-container {
  padding: 6px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}

// prevent side bar
@media (min-width: 950px) and (max-width: 991.98px) {
  .form-container {
    width: 660px;
  }
}
// lg: 992px
@media (min-width: $breakpoint-lg) {
  .form-container {
    width: 660px;
  }
}
</style>
